<template>
  <nav v-if="user.loggedIn">
    <v-app-bar app elevate-on-scroll elevation="3" dark>
      <v-app-bar-nav-icon
        class="grey--text"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-layout row wrap align-content-space-around>
        <v-flex xs11 md12>
          <v-app-bar-title class="text-uppercase grey--text">
            <span class="font-weight-light">Finance</span>
            <span>Assistant</span>
          </v-app-bar-title>
        </v-flex>
      </v-layout>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <span style="cursor: pointer" v-bind="attrs" v-on="on">
            <v-chip link>
              <v-badge dot bottom color="green" offset-y="10" offset-x="10">
                <v-avatar size="40">
                  <v-img src="../assets/noimage.jpg" />
                </v-avatar>
              </v-badge>
              <span class="ml-3">{{ user.data.displayName }}</span>
            </v-chip>
          </span>
        </template>
        <v-list width="300" class="py-0">
          <v-list-item two-line>
            <v-list-item-avatar>
              <img src="../assets/noimage.jpg" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user.data.displayName }}</v-list-item-title>
              <v-list-item-subtitle>{{
                user.data.specialty
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item router to="/information">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Perfil </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item >
          <v-list-item-icon>
            <v-icon>mdi-key</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Cambiar Password
          </v-list-item-title>
        </v-list-item>-->
          <v-list-item @click="signOut()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Cerrar sesión </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app dark>
        <v-layout row align-content-center column>
         <v-card color="Transparent"  class="pa-6" rounded="rounded-xxl">
              <v-img src="../assets/expenses.png"></v-img>
            </v-card>
        </v-layout>
     

      <v-divider></v-divider>

      <v-list nav v-if="user.loggedIn" class="mt-5" >
        <!-- <v-list-item router to="/home" v-if="user.loggedInAll">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
         <v-list-item router to="/dashboards" v-if="user.loggedInAll">
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Indicadores</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->
       
        <v-list-item router to="/monthlyFinance" v-if="user.loggedInAll">
          <v-list-item-icon>
            <v-icon large>attach_money</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Finanzas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
      </v-list>
    </v-navigation-drawer>
  </nav>

  <nav v-else>
    <v-app-bar flat app>
      <v-layout row wrap align-content-space-around>
        <v-flex xs11 md12>
          <v-app-bar-title class="text-uppercase grey--text">
            <span class="font-weight-light">Finance</span>
            <span>Assistant</span>
          </v-app-bar-title>
        </v-flex>
      </v-layout>

      <v-spacer></v-spacer>

      <template>
        <!--   <v-btn
          dark
          small
          rounded
          color="blue darken-1"
          @click="$router.push('/login').catch(() => {})"
        >
          <v-icon left> login </v-icon>
          <span>Login</span>
        </v-btn>
        <v-btn
          text
          small
          rounded
          color="grey darken-1"
          @click="$router.push('/register').catch(() => {})"
        >
          <v-icon left> person_add </v-icon>
          <span>Registrarse</span>
        </v-btn>-->
      </template>
    </v-app-bar>
  </nav>
</template>
<script>
import helperServices from "@/Services/helperServices";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      drawer: null,
      // user: {},
      items: [
        //{ title: "Inicio", icon: "home", to: "/home" },
        //{ title: "Indicadores", icon: "dashboard", to: "/dashboards" },
        { title: "Pacientes", icon: "face", to: "/patients" },
        { title: "Mis datos", icon: "info", to: "/information" },
      ],
      notif: [
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          title: "Brunch this weekend?",
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          title: "Oui oui",
          subtitle:
            '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          title: "Birthday gift",
          subtitle:
            '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
        },
        { divider: true, inset: true },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
          title: "Recipe to try",
          subtitle:
            '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
        },
      ],
      isMobile: false,
    };
  },
  methods: {
    signOut() {
      this.$store.dispatch("fetchUser", null);
      this.$router.push("/login").catch(() => {});
      //localStorage.removeItem('user-token') // clear your user's token from localstorage
      // remove the axios default header
      //delete axios.defaults.headers.common["Authorization"];
    },
  },
  created() {
    //this.user = this.$store.getters.user;
    this.isMobile = helperServices.MobileService.isMobile();
  },
  computed: {
    ...mapGetters({
      nameFromStore: "user",
    }),
    user: {
      get() {
        return this.nameFromStore;
      },
      set(newName) {
        return newName;
      },
    },
  },
};
</script>
