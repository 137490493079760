import store from '../store/index'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

class PrintService {

    printer(titleDoc, titleReport, selectedDate, dataSelectedToPrint, columnsDisplayOrderArray, customColumnsWidthsArray, customHeadersArrays, orientationDescription = "portrait",  pageSizeDescription = "A4") {
        //https://github.com/bpampuch/pdfmake/issues/24              
      
        var docDefinition = {
          info: {
            title: titleDoc,            
          },
          header: {
            columns: [
              {
                text: store.getters.user.data.displayName,
                alignment: "left",
                margin: [20, 15, 10, 0],
              },
              {
                text: "Fecha: " + this.getHourReport(selectedDate),
                alignment: "right",
                margin: [0, 15, 10, 20],
              },
            ],
          },
          pageOrientation: orientationDescription,
          pageSize: pageSizeDescription,
          compress: true,
          // permissions: {
          //   printing: "highResolution", //'lowResolution'
          //   modifying: false,
          //    copying: false,
          //   annotating: true,
          //   fillingForms: true,
          //   contentAccessibility: true,
          //   documentAssembly: true,
          // },
          content: [
            { text: titleReport, style: "header" },
  
            this.table(
              // External data
              dataSelectedToPrint,
              // Columns display order
              columnsDisplayOrderArray,
              // Custom columns widths
              customColumnsWidthsArray,
              // Show headers?
              true,
              // Custom headers
              customHeadersArrays,
              // Custom layout, use '' for no layout
              //""
              "lightHorizontalLines",
              //style
              "tableExample"
            ),
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
              alignment: "center",
            },
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            tableHeader: {
              bold: true,
              fontSize: 13,
              color: "black",
            },
          },
          defaultStyle: {
             //alignment: 'justify'
          },
        };
  
        //con estas lineas aparece un viewer por default de pdfmake solo que al descargar el archivo es mas pesado
        //pdfMake.createPdf(docDefinition).download();
        pdfMake.createPdf(docDefinition).open();
        //pdfMake.createPdf(docDefinition).open({}, window);
      }

      table(data, columns, witdhsDef, showHeaders, headers, layoutDef, styledef) {
        return {
          table: {
            headerRows: 1,
            dontBreakRows: true,
            widths: witdhsDef,
            body: this.buildTableBody(data, columns, showHeaders, headers),
            style: styledef,
            // alignment: "center",
          },
          layout: layoutDef,
        };
      }
    
     buildTableBody(data, columns, showHeaders, headers) {
        var body = [];
        // Inserting headers
        if (showHeaders) {
          body.push(headers);
        }
  
        // Inserting items from external data array
        data.forEach(function (row) {
          var dataRow = [];
           var i = 0;
  
          columns.forEach(function (column) {
            //console.log(column);
               if (column == "value") {
                 dataRow.push({
                   text: Object.byString(row, column),
            //alignment: "center",
            // alignment: headers[i].alignmentChild,
                 });
            } else if (column == "isBillable") {
                 let item = "";
                 if (Object.byString(row, column) === true) item = "SI";
                 else item = "NO";
  
               dataRow.push({
                 text: item,
           // alignment: "center",
           //  alignment: headers[i].alignmentChild,
               });
             } //else if (column == "tarifaConFactura") {
            //   dataRow.push({
            //     text: formatMoney(Object.byString(row, column), ",", "."),
            //alignment: "center",
            // alignment: headers[i].alignmentChild,
            //   });
            // } else {
            // if (column == "isAppointmentHome") {
            //   if (Object.byString(row, column) == "true") {
            //     dataRow.push({
            //       text: "Domicilio",
            //     });
            //   } else {
            //     dataRow.push({
            //       text: "Consultorio",
            //     });
            //   }
              else {
            dataRow.push({
              text: Object.byString(row, column),
              //alignment: "center",
              // alignment: headers[i].alignmentChild,
            });
              }
             i++;
          });
  
          body.push(dataRow);
        });
  
        return body;
      }

      getHourReport(selectedDate) {
        var splitfecha = selectedDate.split("-");
        let cDay = splitfecha[2];
        let cMonth = splitfecha[1];
        let cYear = splitfecha[0];
  
        return cDay + "/" + cMonth + "/" + cYear + " ";
      }
  }
  
  export default PrintService