<template ref="refToElement">
  <div class="MonthlyFinance">
    <LoadingProcess :loadingShow="loadingShow" :loadingTitle="loadingTitle" />
    <v-card flat>
      <v-row class="mt-5">
        <v-toolbar-title>
          <h3 class="headline grey--text ml-3">Finanzas</h3>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-if="!isMobile">
          <v-btn
            rounded
            dark
            @click="addItem()"
            color="primary"
            class="mr-5"
            small
          >
            <v-icon left>add</v-icon>
            Nuevo
          </v-btn>

          <v-btn
            rounded
            dark
            @click="printer()"
            color="black"
            class="mr-5"
            small
          >
            <v-icon left>print</v-icon>
            Imprimir
          </v-btn>
          <v-btn
            rounded
            dark
            @click="showCards()"
            color="orange"
            class="mr-5"
            small
          >
            <v-icon left>bar_chart</v-icon>
            Estadísticas
          </v-btn>
        </template>
        <template v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                large
                @click="addItem()"
                color="primary"
                class="mr-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                large
                @click="printer()"
                color="black"
                class="mr-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>print</v-icon>
              </v-btn>
            </template>
            <span>Imprimir</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                large
                @click="showCards()"
                color="orange"
                class="mr-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>bar_chart</v-icon>
              </v-btn>
            </template>
            <span>Estadísticas</span>
          </v-tooltip>
        </template>
      </v-row>
      <v-card class="mt-5">
        <div>
          <v-toolbar flat v-if="!isMobile">
            <!-- <v-toolbar-title>Lista de pacientes</v-toolbar-title>
             <v-divider class="mx-4" inset vertical></v-divider>
                <v-card-subtitle>Lista de pacientes</v-card-subtitle> -->
            <div class="p-0 m-0 mt-8">
              <v-combobox
                :items="years"
                dense
                outlined
                label="Año"
                v-model="selectedYear"
                @change="getItems"
                @keydown="$event.target.blur()"
                @keypress="$event.target.blur()"
                @keyup="$event.target.blur()"
              ></v-combobox>
            </div>
            <div class="p-0 m-0 mt-8 ml-5">
              <v-autocomplete
                :items="months"
                dense
                outlined
                label="Mes"
                item-text="name"
                item-value="value"
                v-model="selectedMonth"
                @change="getItems"
                @keydown="$event.target.blur()"
                @keypress="$event.target.blur()"
                @keyup="$event.target.blur()"
              ></v-autocomplete>
            </div>
            <div class="p-0 m-0 mt-8 ml-5">
              <v-autocomplete
                :items="billableList"
                dense
                outlined
                label="Facturable"
                item-text="name"
                item-value="value"
                v-model="selectedBillable"
                @change="getItems"
                @keydown="$event.target.blur()"
                @keypress="$event.target.blur()"
                @keyup="$event.target.blur()"
              ></v-autocomplete>
            </div>
            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar registro..."
              single-line
              hide-details
              class="p-0 m-0 mt-6 mb-4"
              full-width
              dense
              outlined
              placeholder="Escribe el paciente o la cantidad para filtrar"
              @change="getItems"
            ></v-text-field>
          </v-toolbar>
          <v-toolbar flat v-else>
            <!-- <v-toolbar-title>Lista de pacientes</v-toolbar-title>
             <v-divider class="mx-4" inset vertical></v-divider>
                <v-card-subtitle>Lista de pacientes</v-card-subtitle> -->
            <v-layout align-content-space-between class="mt-7">
              <v-autocomplete
                :items="years"
                dense
                outlined
                label="Año"
                v-model="selectedYear"
                @change="getItems"
                @keydown="$event.target.blur()"
                @keypress="$event.target.blur()"
                @keyup="$event.target.blur()"
              ></v-autocomplete>
              <v-autocomplete
                class="ml-3"
                :items="months"
                dense
                outlined
                label="Mes"
                item-text="name"
                item-value="value"
                v-model="selectedMonth"
                @change="getItems"
                @keydown="$event.target.blur()"
                @keypress="$event.target.blur()"
                @keyup="$event.target.blur()"
              ></v-autocomplete>
              <v-autocomplete
                class="ml-3"
                :items="billableList"
                dense
                outlined
                label="Facturable"
                item-text="name"
                item-value="value"
                v-model="selectedBillable"
                @change="getItems"
                @keydown="$event.target.blur()"
                @keypress="$event.target.blur()"
                @keyup="$event.target.blur()"
              ></v-autocomplete>
            </v-layout>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalItems"
            :options.sync="options"
            sort-by="date"
            :search="search"
            @update:options="getItems"
            fixed-header
          >
            <template v-slot:top v-if="isMobile">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Buscar registro..."
                single-line
                hide-details
                class="ml-4 mr-4"
                full-width
                dense
                outlined
                placeholder="Escribe el paciente o la cantidad para filtrar"
                @change="getItems"
              ></v-text-field>
            </template>
            <template v-slot:[`item.value`]="{ item }">
              <span>{{ item.value | currency }}</span>
            </template>
            <template v-slot:[`item.isBillable`]="{ item }">
              <v-simple-checkbox
                v-model="item.isBillable"
                disabled
              ></v-simple-checkbox>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    medium
                    class="mr-2"
                    color="blue darken-2"
                    @click.stop="editItem(item)"
                  >
                    edit
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    medium
                    class="mr-2"
                    color="red darken-2"
                    @click.stop="deleteItem(item)"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template>
          </v-data-table>

          <v-snackbar v-model="snackbar" bottom>
            <!-- <v-icon dark> mdi-checkbox-marked-circle </v-icon>-->
            {{ messageSnackbar }}
            <template v-slot:action="{ attrs }">
              <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                Cerrar
              </v-btn>
            </template>
          </v-snackbar>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>Eliminar</v-card-title>
              <v-card-subtitle
                >Desea eliminar el registro
                {{ itemSelected.value }} ?</v-card-subtitle
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDelete">Cancelar</v-btn>
                <v-btn color="error" @click="deleteItemConfirm">Eliminar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="AddItemDialog"
            width="500px"
            persistent
            transition="dialog-bottom-transition"
            scrollable
          >
            <EditMonthlyFinance
              :itemSelected="itemSelected"
              :headerTitle="headerTitle"
              :buttonTitle="buttonTitle"
              @closeEditItem="closeWinEditItem"
            />
          </v-dialog>
          <v-dialog
            v-model="showCardsDialog"
            max-width="750"
            persistent
            transition="dialog-bottom-transition"
            scrollable
          >
            <v-card>
              <v-sheet outlined color="blue" rounded="0" max-height="13">
                <br />
              </v-sheet>
              <v-toolbar flat>
                <v-toolbar-title>
                  <div v-if="selectedMonth == 0">Año {{ selectedYear }}</div>
                  <div class="ml-3" v-else>
                    {{ months[selectedMonth].name }} {{ selectedYear }}
                  </div>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  bottom
                  right
                  offset-y
                  @click="showCardsDialog = false"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-row dense justify="space-between">
                  <v-col cols="12" md="4" v-show="selectedBillable == '1'">
                    <v-card color="green" dark height="120">
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-title class="text-h5">
                            <div class="text-overline white--text">
                              Facturable
                            </div>
                          </v-card-title>

                          <v-card-subtitle>
                            <div class="text-h6 white--text text-capitalize">
                              {{ TotalAmountBillable | currency }}
                            </div>
                          </v-card-subtitle>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4" v-show="selectedBillable == '0'">
                    <v-card color="grey" dark height="120">
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-title class="text-h5">
                            <div class="text-overline white--text">
                              No facturable
                            </div>
                          </v-card-title>

                          <v-card-subtitle>
                            <div class="text-h6 white--text text-capitalize">
                              {{ TotalAmountNoBillable | currency }}
                            </div>
                          </v-card-subtitle>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4" v-show="selectedBillable == '-1'">
                    <v-card color="green" dark height="120">
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-title class="text-h5">
                            <div class="text-overline white--text">
                              Facturable
                            </div>
                          </v-card-title>

                          <v-card-subtitle>
                            <div class="text-h6 white--text text-capitalize">
                              {{ TotalAmountBillable | currency }}
                            </div>
                          </v-card-subtitle>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4" v-show="selectedBillable == '-1'">
                    <v-card color="grey" dark height="120">
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-title class="text-h5">
                            <div class="text-overline white--text">
                              No facturable
                            </div>
                          </v-card-title>

                          <v-card-subtitle>
                            <div class="text-h6 white--text text-capitalize">
                              {{ TotalAmountNoBillable | currency }}
                            </div>
                          </v-card-subtitle>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import apiServices from "../Services/apiServices";
import helperServices from "../Services/helperServices";
import EditMonthlyFinance from "../components/EditMonthlyFinance.vue";
import LoadingProcess from "../components/LoadingProcess.vue";

//https://sweetalert2.github.io/
//https://www.youtube.com/watch?v=UQs0TJp81sE
//https://medium.com/@mehdi.jai/create-a-many-to-many-crud-application-with-electronjs-vuejs-sqlite-and-sequelize-orm-part-2-974457b255da
export default {
  components: { EditMonthlyFinance, LoadingProcess },

  data() {
    const defaultForm = Object.freeze({
      id: -1,
      date: new Date().toISOString().substr(0, 10),
      value: "",
      isBillable: false,
      clientName: "",
    });
    return {
      itemSelected: Object.assign({}, defaultForm),
      AddItemDialog: false,
      showCardsDialog: false,
      headerTitle: "",
      buttonTitle: "",
      typeExpense: "normal",
      search: "",
      dialogDelete: false,
      // dialogInfo: false,
      headers: [
        { text: "Fecha", align: "start", value: "date", sortable: true },
        { text: "Cantidad", value: "value" },
        { text: "Facturable", value: "isBillable" },
        { text: "Paciente", value: "clientName" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      items: [],
      options: {},
      totalItems: 0,
      itemsPerPage: 0,
      page: 0,
      snackbar: false,
      messageSnackbar: "",
      infoItem: {
        date: new Date().toISOString().substr(0, 10),
        value: "",
        isBillable: false,
        clientName: "",
      },
      loadingShow: false,
      loadingTitle: "",
      isMobile: false,
      years: this.getYears(),
      months: [
        { name: "Todos", value: 0 },
        { name: "Enero", value: 1 },
        { name: "Febrero", value: 2 },
        { name: "Marzo", value: 3 },
        { name: "Abril", value: 4 },
        { name: "Mayo", value: 5 },
        { name: "Junio", value: 6 },
        { name: "Julio", value: 7 },
        { name: "Agosto", value: 8 },
        { name: "Septiembre", value: 9 },
        { name: "Octubre", value: 10 },
        { name: "Noviembre", value: 11 },
        { name: "Didciembre", value: 12 },
      ],
      billableList: [
        { name: "Todos", value: -1 },
        { name: "SI", value: 1 },
        { name: "NO", value: 0 },
      ],
      selectedYear: new Date().getFullYear(),
      selectedMonth: 0,
      selectedBillable: -1,
      TotalAmountNoBillable: 0,
      TotalAmountBillable: 0,
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getItems;
      },
      deep: true,
    },
  },

  mounted() {
    //this.useruid = this.$store.getters.user.data.uid;
    this.loadingTitle = "Cargando lista...";

    this.isMobile = helperServices.MobileService.isMobile();
  },
  created() {
    //this.getStats();
  },

  methods: {
    initialize() {},

    getYears() {
      var items = [];
      var startYear = new Date().getFullYear() - 4;
      for (var i = 0; i < 7; i++) {
        items.push(startYear++);
      }
      return items;
    },
    getStats() {
      let sumbillable = 0;
      let sumnobillable = 0;
      this.items.forEach((item) => {
        if (item.isBillable) sumbillable += parseFloat(item.value);
        else sumnobillable += parseFloat(item.value);
      });

      this.TotalAmountBillable = sumbillable;
      this.TotalAmountNoBillable = sumnobillable;
    },
    getItems() {
      const { page, itemsPerPage } = this.options;

      // sortBy,sortDesc
      var filter = {
        searchText: this.search,
        userUID: null,
        PageSize: itemsPerPage,
        PageNumber: page,
        Year: this.selectedYear,
        Month: this.selectedMonth,
        isBillable: this.selectedBillable,
      };

      this.loadingShow = true;
      apiServices.MonthlyFinanceService.GetAllPagination(filter)
        .then((resp) => {
          var data = resp.data.data;
          data.forEach(function (part) {
            part.date = part.date.substr(0, 10);
            return part.date;
          });

          this.items = data;
          this.totalItems = resp.data.meta.totalCount;
          this.getStats();
          //  this.currentPage = resp.data.meta.currentPage;
          this.loadingShow = false;
        })
        .catch((error) => {
          this.loadingShow = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    addItem() {
      this.headerTitle = "Agregar registro";
      this.buttonTitle = "Guardar";
      this.itemSelected = Object.assign({}, this.defaultForm);
      this.itemSelected.id = -1;
      this.AddItemDialog = true;
    },
    showCards() {
      this.showCardsDialog = true;
    },
    editItem(item) {
      //item.date = new Date(item.date).toISOString().split("T")[0];
      this.headerTitle = "Editar registro";
      this.buttonTitle = "Actualizar";
      this.itemSelected = item;
      this.AddItemDialog = true;
    },

    deleteItem(item) {
      this.itemSelected = item;
      this.$fire({
        title: "Desea eliminar el registro?",
        text: item.value,
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // console.log(result);
        if (result.value === true) {
          this.deleteItemConfirm();
          this.$fire({
            position: "top-end",
            title: "Registro eliminado correctamente!",
            // text: "El registro fue eliminado de la base de datos",
            type: "success",
            timer: 3000,
            toast: true,
            showConfirmButton: false,
          }).then(() => {
            //console.log(r.value);
          });
        }
      });
    },

    deleteItemConfirm() {
      if (this.itemSelected) {
        apiServices.ExpenseService.DeleteExpense(this.itemSelected.id)
          .then(() => {
            this.search = "";
            this.getItems();
          })
          .catch((error) => {
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeWinEditItem(result) {
      if (result.tipo == "cerrar") {
        this.AddItemDialog = result.isVisible;
      } else if (result.tipo == "nuevo") {
        this.AddItemDialog = result.isVisible;

        this.$fire({
          position: "top-end",
          title: "Registro creado correctamente",
          // text: "Operación exitosa!!!",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
        this.getItems();
      } else if (result.tipo == "editar") {
        this.AddItemDialog = result.isVisible;
        this.getItems();
        //https://www.npmjs.com/package/vue-simple-alert
        this.$fire({
          position: "top-end",
          title: "Registro modificado correctamente",
          // text: "Edición exitosa!!!",
          type: "success",
          timer: 3000,
          toast: true,
          //grow:'row',
          //width: 300,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      }
    },
    printer() {
      //parametros para imprimir
      var titleDoc = "Reporte finanzas"; //titulo de la pestaña, nuevo documento
      var titleReport = "Movimientos"; //titulo del reporte
      //datos para imprimir
      var dataSelectedToPrint = this.items;
      //orden de las columnas, debe hacer match con los nombres de la coleccion de datos
      var columnsDisplayOrderArray = [
        "date",
        "value",
        "isBillable",
        "clientName",
      ];
      //tamaño de las columnas, en el mismo orden que columnsDisplayOrderArray
      var customColumnsWidthsArray = ["auto", "auto", "auto", "auto"];
      //orden de las columnas de la cabecera de la tabla a imprimir
      var customHeadersArrays = [
        {
          text: "Fecha",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Cantidad",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Facturable",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Paciente",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
      ];
      var orientationDescription = "Portrait"; // Portrait or Landscape.
      var pageSizeDescription = "A4";
      // itemsSelected = itemsSelectedfromDay.sort((a, b) => {
      //   return b.start - a.start;
      //  });

      //se llama al metodo imprimir
      helperServices.PrintService.printer(
        titleDoc,
        titleReport,
        new Date().toISOString().substr(0, 10),
        dataSelectedToPrint,
        columnsDisplayOrderArray,
        customColumnsWidthsArray,
        customHeadersArrays,
        orientationDescription,
        pageSizeDescription
      );
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
