<template>
  <div>
    <v-app class="gey lighten-4">
      <Navbar />
      <v-main class="mx-4 mb-4">
        <router-view></router-view>
      </v-main>
    </v-app>
  </div>
</template>

<script>

import Navbar from "@/components/Navbar";
import Axios from "axios";
export default {
  name: "App",
  components: { Navbar },
  data: () => ({}),

  methods: {},
  created() {
    Axios.interceptors.response.use(
      (response) => {
        if (response.status === 401 || response.status === 404) {
          //add your code
          //alert("You are not authorized");
          this.$fire({
            position: "top-end",
            title: "No estas autorizado!!!",
            // text: "Edición exitosa!!!",
            type: "success",
            timer: 3000,
            toast: true,
            //grow:'row',
            //width: 300,
            showConfirmButton: false,
          }).then(() => {
            //console.log(r.value);
          });

          this.$store.dispatch("fetchUser", null);
          this.$router.push("/login").catch(() => {});
          console.log("You are not authorized");
        }
        return response;
      },
      (error) => {
        if (error.response && error.response.data) {
          //add your code
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
      }
    );
  },
  mounted() {
    document.title = "FinanceAssistant";
  },
};
</script>
<style></style>
