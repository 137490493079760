import store from '../store/index'

class MonthlyFinanceService {
    axios
    baseUrl
    
    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}MonthlyFinance`
    }
    
    GetById(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetById/?id=${id}`);
    }

    GetAll() {        
        let self = this;   
        
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/Get`);
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }*/
    }
    GetAllPagination(filter) {        
      let self = this;   
      
      self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
      return self.axios.post(`${self.baseUrl}/GetAll`,filter);
        /*{
          headers: {
            'Authorization': "Bearer " + token
          }
        }*/
  }
  

    Insert(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.post(`${self.baseUrl}/Insert`, model);
    }

    Update(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.put(`${self.baseUrl}/Update`, model);
    }

    Delete(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.delete(`${self.baseUrl}/Delete/${id}`);
    }
}

export default MonthlyFinanceService